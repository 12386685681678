<template>
  <div>
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">No. Kontrak</label>
          <vs-input class="w-full" v-model="data.no_kontrak" disabled/>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Tgl. Kontrak</label>
          <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Masa Garansi (hari)</label>
          <vs-input class="w-full" v-model="data.masa_garansi"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Koordinator</label>
          <vs-input class="w-full" v-model="data.nama_staf" disabled/>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Koordinator</label>
          <vs-input class="w-full" v-model="data.nama_staf_atasan" disabled/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/1 w-full">
          <label class="ml-1 text-xs">Keterangan Kontrak</label>
          <vs-textarea class="w-full" v-model="data.keterangan_kontrak"/>
        </div>
      </div>

      <vs-divider> Harga Unit </vs-divider>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">HPP Tanah</label>
          <v-money class="w-full" v-model="data.properti_unit.hpp_tanah" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">HPP Bangunan</label>
          <v-money class="w-full" v-model="data.properti_unit.hpp_bangunan" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total HPP</label>
          <v-money class="w-full" :value="data.properti_unit.hpp_tanah + data.properti_unit.hpp_bangunan" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Harga Standar</label>
          <v-money class="w-full" v-model="data.harga_standar" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Kel. Luas</label>
          <v-money class="w-full" v-model="data.harga_luas" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Kel. Tinggi</label>
          <v-money class="w-full" v-model="data.harga_tinggi" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya View</label>
          <v-money class="w-full" v-model="data.harga_view" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Mutu</label>
          <v-money class="w-full" v-model="data.harga_mutu" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya ADM</label>
          <v-money class="w-full" v-model="data.biaya_adm" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Peningkatan Hak</label>
          <v-money class="w-full" v-model="data.biaya_notaris" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">DPP</label>
          <v-money class="w-full" v-model="data.dpp" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Total Harga Unit</label>
          <v-money class="w-full" v-model="data.total_harga_unit" readonly/>
        </div>
      </div>

      <vs-divider> Pembayaran </vs-divider>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Rencana Bayar</label>
          <v-select :options="caraBayars" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_cara_bayar" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Bank</label>
          <v-select :options="bankKprs" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_bank_kpr" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">Diskon %</label>
          <v-money class="w-full" v-model="data.diskon_persen" :precision="2" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">Diskon Jml</label>
          <v-money class="w-full" v-model="data.diskon_jml" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">PPN %</label>
          <v-money class="w-full" v-model="data.ppn_persen" :precision="2" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">PPN Jml</label>
          <v-money class="w-full" v-model="data.ppn_jml" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">PPh %</label>
          <v-money class="w-full" v-model="data.pph_persen" :precision="2" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">PPh Jml</label>
          <v-money class="w-full" v-model="data.pph_jml" readonly/>
        </div>
      </div>

      <vs-divider> Booking Fee </vs-divider>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Tgl. Mulai Jatuh Tempo</label>
          <vs-input class="w-full" v-model="data.booking_fee_due" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Booking Fee Status</label>
          <vs-input class="w-full" v-model="data.booking_fee_status" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total Booking Fee</label>
          <v-money class="w-full" v-model="data.booking_fee" readonly/>
        </div>
      </div>

      <vs-divider> Uang Muka </vs-divider>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Uang Muka %</label>
          <v-money class="w-full" v-model="data.dp_persen" :precision="2" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Termin Uang Muka ( X )</label>
          <vs-input class="w-full" v-model="data.termin_dp" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total Uang Muka</label>
          <v-money class="w-full" :value="data.dp_jml" readonly/>
        </div>
      </div>

      <vs-divider> Angsuran </vs-divider>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Start Due</label>
          <vs-input class="w-full"  v-model="data.angsuran_start_due" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Termin Angsuran ( X )</label>
          <vs-input class="w-full"  v-model="data.termin_angsuran" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total Angsuran ( X )</label>
          <v-money class="w-full" :value="data.angsuran_jml" readonly/>
        </div>
      </div>

      <div class="vx-row mb-3 mt-base">
        <div class="vx-col sm:w-12/12 w-full">
          <p class="w-full text-right text-warning text-xl">Grand Total: <span class="font-bold">{{ data.grand_total | idr }}</span></p>
        </div>
      </div>

      <div class="vx-row float-right mt-6">
        <div class="vx-col w-full">
          <div class="flex items-center space-x-3 opacity-50">
            <p>Data sudah terposting tidak dapat diubah.</p>
            <vs-button :disabled="isLoading || !!data.id_jurnal" color="primary" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </div>

    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Data Staf"
              :active="isModalStafActive"
              v-on:update:active="isModalStafActive = $event">
      <Staf v-if="isActive" :selectable="true" @selected="onSelectedModalStaf"/>
    </vs-popup>

  </div>
</template>

<script>
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import BankKprRepository from '@/repositories/master/bank-kpr-repository.js'
import KontrakRepository from '@/repositories/marketing/kontrak/kontrak-repository'
import Staf from '@/views/pages/master/staf/Staf'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'

export default {
  name: 'TabKontrakD',
  props: ['isActive'],
  components: {
    Staf,
    flatPickr,
    ValidationErrors,
    'v-select': vSelect,
    VMoney
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      isModalStafActive: false,
      errors: null,
      data: {
        properti_unit: {}
      },
      caraBayars: [],
      bankKprs: []
    }
  },
  methods: {
    initData () {
      this.getAllCaraBayar()
      this.getAllBankKpr()
      this.getKontrak()
      this.isDataInited = true
    },

    getKontrak () {
      this.isLoading = true

      const idKontrak = this.$route.params.idKontrak
      const params = { withUnitInformation: true }
      KontrakRepository.show(idKontrak, params)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSelectedModalStaf (item) {
      this.data.staf = item
      this.data.id_staf = item.id
      this.data.nama_staf = item.nama
      this.isModalStafActive = false
    },

    getAllCaraBayar () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'KONTRAK' })

      CaraBayarRepository.getAll(params)
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllBankKpr () {
      BankKprRepository.getAll()
        .then(response => {
          this.bankKprs = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true
      const params = _.pick(this.data, ['id', 'tgl', 'masa_garansi', 'keterangan_kontrak', 'id_cara_bayar', 'id_bank_kpr'])

      KontrakRepository.update(params)
        .then(response => {
          this.notifySuccess('Data berhasil disimpan.')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
            this.notifyError('Data tidak valid.')
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
